<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="wrap bgw coupon">
          <div class="nav">
            <div @click="navTab = 0" :class="{navActive: navTab == 0}">
              可用优惠券
              <i></i>
            </div>
            <!-- <div @click="navTab = 1" :class="{navActive: navTab == 1}">
              已使用优惠券
              <i></i>
            </div>-->
            <div @click="navTab = 2" :class="{navActive: navTab == 2}">
              已过期优惠券
              <i></i>
            </div>
          </div>
          <div v-if="navTab == 0" class="content">
            <div class="list" v-for="(v, i) in nouseCoupons" :key="i">
              <div class="top">
                <p>￥{{v.money}}</p>
                <p class="one">满{{v.limit}}可用</p>
                <p>{{ v.start_time.split(" ")[0] }} - {{ v.end_time.split(" ")[0] }}</p>
				<p>门店：{{v.shop_name}}</p>
              </div>
              <div class="bottom">
                <p @click="GoUseCoupon(v)" class="use_coupon">立即使用</p>
              </div>
            </div>
          </div>
          <!-- 已使用 -->
<!--          <div v-if="navTab == 2" class="content">
            <div class="list">
              <div class="top none">
                <p>￥100</p>
                <p class="one">满200可用</p>
                <p>2018.10.10-219.10.10</p>
              </div>
              <div class="bottom">
                <img src="@/assets/image/Coupon_Alreadyused@2x.png" alt />
              </div>
            </div>
          </div> -->
          <div v-if="navTab == 2" class="content">
            <div class="list" v-for="(v, i) in useCoupons" :key="i">
              <div class="top none">
                <p>￥{{v.money}}</p>
                <p class="one">满{{v.limit}}可用</p>
                <p>{{ v.start_time.split(" ")[0] }} - {{ v.end_time.split(" ")[0] }}</p>
				<p>门店：{{v.shop_name}}</p>
              </div>
              <div class="bottom">
                <img src="@/assets/image/Coupon_Beoverdue@2x.png" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "./header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部
import { usergetinfo, message } from "@/request/user";
import { getMyCoupons } from "@/request/user";
export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      userList: [], // 个人信息
      navTab: 0,
      nouseCoupons: [],
      useCoupons: []
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }

    this.init();
    // this.state=this.$route.query.state
  },
  methods: {
    init() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });

      // 获取我的优惠券
      getMyCoupons({ token: this.$tokens }).then(res => {
        this.nouseCoupons = res.data;
      });

      getMyCoupons({ token: this.$tokens, isexpire: 1 }).then(res => {
        if (res.code == 1) {
          this.useCoupons = res.data;
        } else {
          this.useCoupons = [];
        }
      });
      // 获取已过期的优惠券
    },
    GoUseCoupon(v) {
      // console.log(v)
      this.$router.push("/allGoods")
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  min-height: 600px;
  display: flex;
  padding-top: 36px;
  padding-left: 56px;
  flex-wrap: wrap;
  color: white;
}
.list {
  width: 324px;
  height: 366px;
  margin-bottom: 60px;
  margin-right: 56px;
  text-align: center;
  .top {
    height: 222px;
    background: #4eeec8;
    padding-top: 56px;
	p{
		margin-bottom: 10px;
	}
  }
  .none {
    background: #cccccc;
	
	
  }
  .bottom {
    height: 154px;
    background: #f5f5f5;
    padding-top: 56px;
    position: relative;
    p:nth-of-type(1) {
      color: #4eeec8;
      font-size: 16px;
      width: 110px;
      height: 40px;
      line-height: 40px;
      margin: auto;
      background: rgba(245, 245, 245, 1);
      border: 2px solid rgba(78, 238, 200, 1);
      border-radius: 20px;
    }
    img {
      position: absolute;
      bottom: 20px;
      right: 26px;
      width: 74px;
      height: 74px;
    }
    .use_coupon {
      cursor: pointer;
    }
  }
  p:nth-of-type(1) {
    font-size: 36px;
    margin-bottom: 18px;
  }
  p:nth-of-type(2) {
    font-size: 16px;
    margin-bottom: 24px;
  }
  p:nth-of-type(3) {
    font-size: 14px;
  }
}
.nav {
  height: 68px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 18px;
  padding-left: 20px;
  display: flex;
  > div {
    line-height: 68px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    cursor: Pointer;
  }
}
.navActive {
  color: #4eeec8;

  i {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    border-bottom: 2px solid #4eeec8;
  }
}
.coupon {
  border: 1px solid #e6e6e6;
}
</style>